<template>
  <div class="subscriptionAnalyticsDashboard js-tabBox subscriptionAnalyticsDashboard--admin">
    <div class="subscriptionAnalyticsDashboard__header">
      <div class="subscriptionAnalyticsDashboard__title">Subscription analytics</div>
      <router-link class="subscriptionAnalyticsDashboard-export__button" to="">
        <span
          @click="exportToExcel()"
          class="text">Export
        </span>
      </router-link>
    </div>
    <div class="subscriptionAnalyticsDashboard__content">
      <div class="subscriptionAnalyticsDashboard__content-item js-tabContentItem">
          <div class="subscriptionAnalyticsDashboard__table">
            <Pagination
            :pageCount="subscriptionAnalyticsListPageCount"
            :rowCount="subscriptionAnalyticsListCount"
            @pageClick="onSubscriptionAnalyticsPageClick"
          />
          <div class="subscriptionAnalyticsDashboard__table-header">
            <div class="subscriptionAnalyticsDashboard__table-cell">№</div>
            <div class="subscriptionAnalyticsDashboard__table-cell">CreatedOn</div>
            <div class="subscriptionAnalyticsDashboard__table-cell">Name</div>
            <div class="subscriptionAnalyticsDashboard__table-cell">Email</div>
            <div class="subscriptionAnalyticsDashboard__table-cell">Phone</div>
            <div class="subscriptionAnalyticsDashboard__table-cell">LastLoginDate</div>
            <div class="subscriptionAnalyticsDashboard__table-cell">Action</div>
            <div class="subscriptionAnalyticsDashboard__table-cell">IsMobileSignUp</div>
          </div>
          <div class="subscriptionAnalyticsDashboard__table-body js-customScroll">
            <vue-scroll :ops="ops">
              <div
                v-for="(item, index) in subscriptionAnalyticsList"
                :key="item.id"
                class="subscriptionAnalyticsDashboard__table-row js-acc-item"
              >
                <div class="subscriptionAnalyticsDashboard__table-row-header js-acc-title">
                  <div class="subscriptionAnalyticsDashboard__table-cell">
                    <div class="subscriptionAnalyticsDashboard__table-index">{{subscriptionAnalyticsList.length - index}}</div>
                  </div>
                  <div class="subscriptionAnalyticsDashboard__table-cell">
                    <span>{{getDisplayDateTime(item.createdOn)}}</span>
                  </div>
                  <div class="subscriptionAnalyticsDashboard__table-cell">{{item.name}}</div>
                  <div class="subscriptionAnalyticsDashboard__table-cell">{{item.email}}</div>
                  <div class="subscriptionAnalyticsDashboard__table-cell">
                    <div class="subscriptionAnalyticsDashboard__table-phone">{{item.mobile}}</div>
                  </div>
                  <div class="subscriptionAnalyticsDashboard__table-cell">
                    <span>{{getDisplayDateTime(item.lastLogInDate)}}</span>
                  </div>
                  <div class="subscriptionAnalyticsDashboard__table-cell">{{item.action}}</div>
                  <div class="subscriptionAnalyticsDashboard__table-cell">
                    <PlaneCheckbox :isChecked="item.isMobileSignUp" :isDisabled="true" style="justify-content: center"/>
                  </div>
                </div>
              </div>
            </vue-scroll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { reportsAPI } from '@/api/reports'
  import { Tabs } from '@/js/plugins/tabs'
  import Pagination from '@/components/common/Pagination/Pagination'
  import './SubscriptionAnalytics.styl'
  import moment from 'moment'
  import PlaneCheckbox from '@/components/common/Checkbox/PlaneCheckbox.vue';

  export default {
    name: 'SubscriptionAnalytics',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      ops: {
        rail: {
          size: '3px',
        },
        bar: {
          size: '3px',
          background: 'rgba(0,0,0,0.1)',
        },
      },
      tabSelected: 'subscriptionAnalytics',
    }),
    computed: {
      subscriptionAnalyticsList () {
        return this.$store.state.subscriptionAnalytics.subscriptionAnalyticsList
      },
      subscriptionAnalyticsListPageCount () {
        return this.$store.state.subscriptionAnalytics.subscriptionAnalyticsListPageCount
      },
      subscriptionAnalyticsListCount () {
        return this.$store.state.subscriptionAnalytics.subscriptionAnalyticsListCount
      },
    },
    mounted () {
      this.tabs.addActiveForMouted()
    },
    methods: {
      moment,
      async exportToExcel () {
        try {
          const { data } = await reportsAPI.subscriptionAnalytics()
          const downloadLink = document.createElement('a');
          const blob = new Blob(['\ufeff', data]);
          const url = window.URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = 'subscriptionAnalytics_report_' + new Date().toLocaleDateString().replaceAll('/', '-') + '.csv';
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } catch (e) {
          console.log(e)
        }
      },
      async onSubscriptionAnalyticsPageClick (data) {
        await this.$store.dispatch('getSubscriptionAnalyticsList', data);
      },
      getDisplayDateTime (dateString) {
      const options = {timeZone: 'America/New_York', hour12: false};
      let date = new Date(dateString);
      date = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
      return dateString === null
        ? ''
        : date.toLocaleString('en-US', options);
    },
    },
    components: {
      Pagination,
      PlaneCheckbox,
    },
  }
</script>